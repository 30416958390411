<template>
  <el-dialog
    title="Agenda de contatos"
    v-model="shouldShow"
    v-loading="isLoading"
  >
    <el-input
      placeholder="Digite o nome e pressione Enter"
      v-model="contactsSearch"
      v-on:keyup.enter="fetchContacts"
      class="input-with-select"
    >
    </el-input>
    <el-table :data="contacts" style="width: 100%; z-index: 0">
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column prop="phone" label="telefone"> </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openContactModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openWhatsAppMessageModal(cat.row)"
              >
                <i class="el-icon-position"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteContact(cat.row)"
                title="
              Remover o contato?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <contact-modal
      :showModal="showContactModal"
      :contact="contact"
      type="Agenda"
      @close-modal="showContactModal = false"
      @should-update="fetchContacts"
    ></contact-modal>
    <whats-app-message-modal
      :showModal="showWhatsAppMessageModal"
      :contact="contact"
      @close-modal="showWhatsAppMessageModal = false"
    ></whats-app-message-modal>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
//import { ElNotification } from "element-plus";
import ContactModal from "../modals/ContactModal.vue";
import WhatsAppMessageModal from "../modals/WhatsAppMessageModal.vue";

export default {
  props: ["event", "showModal"],
  components: { ContactModal, WhatsAppMessageModal },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      isLoading: false,
      showContactModal: false,
      showWhatsAppMessageModal: false,
      contacts: null,
      contact: null,
      contactsSearch: "",
    };
  },
  watch: {
    showModal(v) {
      if (v) this.fetchContacts;
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    isNew() {
      return !("uid" in (this.event || {}));
    },
  },
  methods: {
    deleteContact(c) {
      fetch(`${this.$store.state.apiUrl}contacts/${c.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(() => {
          this.fetchContacts();
          this.$notify({
            title: "Contato removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover o contato",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    openContactModal(c) {
      this.contact = c;
      this.showContactModal = true;
    },
    openWhatsAppMessageModal(c) {
      this.contact = c;
      this.showWhatsAppMessageModal = true;
    },
    fetchContacts() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}contacts`);
      url.search = new URLSearchParams({ searchName: this.contactsSearch });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then(
          (json) => (this.contacts = json?.filter((c) => c.name && c.phone))
        )
        .finally(() => (this.isLoading = false));
    },
  },
  name: "ContactsModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>