<template>
  <el-card v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Minha Agenda</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-phone"
              size="medium"
              @click="openContacts()"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-row type="flex" justify="space-between">
      <h4>
        {{ calendar?.currentData?.viewTitle }}
      </h4>
      <el-button-group>
        <el-button
          type="primary"
          icon="el-icon-d-arrow-left"
          @click="backMonths(12)"
        ></el-button>
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          @click="backMonths(1)"
        ></el-button>
        <el-button
          type="primary"
          icon="el-icon-arrow-right"
          @click="advanceMonths(1)"
        ></el-button>
        <el-button
          type="primary"
          icon="el-icon-d-arrow-right"
          @click="advanceMonths(12)"
        ></el-button>
      </el-button-group>
    </el-row>
    <base-calendar
      id="studentCalendar"
      :key="calendarKey"
      :options="CalendarOptions"
      :events="CompiledEvents"
      @get-calendar="calendar = $event"
    ></base-calendar>
    <event-modal
      :showModal="showEventModal"
      :event="event"
      @close-modal="showEventModal = false"
      @should-update="fetchEvents"
    ></event-modal>
    <contacts-modal
      :showModal="showContactsModal"
      @close-modal="showContactsModal = false"
    ></contacts-modal>
  </el-card>
</template>
<script>
//import { ElNotification } from "element-plus";
import BaseCalendar from "../components/BaseCalendar.vue";
import ContactsModal from "./modals/ContactsModal.vue";
//import VueApexCharts from "vue3-apexcharts";
import { uuid } from "vue-uuid";
import EventModal from "./modals/EventModal.vue";
export default {
  components: { EventModal, BaseCalendar, ContactsModal },
  name: "MyAgenda",
  computed: {
    CompiledEvents() {
      return (
        this?.events?.map((e) => ({
          title: e.title,
          id: e.uid,
          start: e.start_at,
          end: e.expire_at ? e.expire_at : e.start_at,
          object: e,
          allDay: true,
          backgroundColor: this.showEventWithColors(e.priority),
          editable: true,
        })) || []
      );
    },
    CalendarOptions() {
      const _this = this;
      return {
        initialView: "dayGridMonth",
        selectable: true,
        headerToolbar: false,
        initialDate: this.referenceDate,
        locale: "pt-br",
        dateClick(info) {
          _this.openEventModal({ start_at: info.date });
        },
        eventClick(info) {
          _this.openEventModal({ ...info.event.extendedProps.object });
        },
      };
    },
  },
  mounted() {
    this.fetchContacts();
    this.fetchEvents();
  },
  methods: {
    showEventWithColors(eventPriority) {
      return this.eventColors[`${eventPriority}`] || this.eventColors["normal"];
    },
    rebuildCalendar() {
      this.calendarKey = uuid.v4();
    },
    openEventModal(e) {
      this.showEventModal = true;
      this.event = e;
    },
    fetchContacts() {
      fetch(`${this.$store.state.apiUrl}contacts`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then(
          (json) => (this.contacts = json?.filter((c) => c.name && c.phone))
        )
        .finally(() => (this.isLoading = false));
    },
    backMonths(months) {
      this.referenceDate = new Date(
        this.referenceDate.getFullYear(),
        this.referenceDate.getMonth() - months,
        1
      );

      //this.calendar.gotoDate(this.referenceDate);
      if (months === 12) this.calendar.prevYear();
      else this.calendar.prev();

      this.fetchEvents();
    },
    advanceMonths(months) {
      this.referenceDate = new Date(
        this.referenceDate.getFullYear(),
        this.referenceDate.getMonth() + months,
        1
      );

      //this.calendar.gotoDate(this.referenceDate);
      if (months === 12) this.calendar.nextYear();
      else this.calendar.next();

      this.fetchEvents();
    },
    firstDayInMonth() {
      return new Date(
        this.referenceDate.getFullYear(),
        this.referenceDate.getMonth(),
        1
      );
    },
    lastDayInMonth() {
      return new Date(
        this.referenceDate.getFullYear(),
        this.referenceDate.getMonth() + 1,
        0
      );
    },
    openContacts() {
      this.showContactsModal = true;
    },
    fetchEvents() {
      const url = new URL(`${this.$store.state.apiUrl}events`);
      url.search = new URLSearchParams({
        start_at: this.firstDayInMonth(),
        expire_at: this.lastDayInMonth(),
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then((json) => (this.events = json))
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    events() {
      this.rebuildCalendar();
    },
  },
  data() {
    return {
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        month: "long",
        year: "numeric",
      }),
      showContactsModal: false,
      currencyFormatter: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
      showEventModal: false,
      contacts: null,
      referenceDate: new Date(),
      calendar: null,
      calendarKey: uuid.v4(),
      isLoading: false,
      eventColors: {
        normal: "#0069c0",
        urgent: "#fb8c00",
        warning: "#006978",
      },
      event: null,
      events: [],
    };
  },
};
</script>
<style scoped>
.el-row {
  margin-bottom: 8px;
}
.m-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>