<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <base-input label="Título:" v-model="event_.title"> </base-input>
      <base-input label="Descrição:" v-model="event_.details"> </base-input>
      <el-date-picker
        v-model="eventDate"
        class="mt-1 stretch-width"
        type="datetimerange"
        start-placeholder="Início"
        end-placeholder="Fim"
        unlink-panels
        size="medium"
        :default-time="[new Date(2020, 1, 1, 8), new Date(2020, 1, 1, 8)]"
        format="DD/MM/YYYY HH:mm:ss"
      >
      </el-date-picker>
      <el-select v-model="event_.priority" size="medium">
        <template #prefix>Prioridade</template>
        <el-option
          v-for="item in EventPriorities"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-model="event_.visibility" size="medium">
        <template #prefix>Visibilidade</template>
        <el-option
          v-for="item in EventVisibility"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        multiple
        :disabled="!isNew"
        clearable
        v-model="event_.repeat_event_in_months"
        size="medium"
      >
        <template #prefix>Repetir evento</template>
        <el-option
          v-for="item in MonthsToRepeat"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        multiple
        clearable
        v-model="event_.days_to_notificate"
        size="medium"
      >
        <template #prefix>Notificar no WhatsApp</template>
        <el-option
          v-for="item in DaysToNotificate"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="save">Salvar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["event", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      event_: this.category || {},
      eventDate: [],
      isLoadingSave: false,
      systemActions: [],
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Fim do mês",
          value: (() => {
            return new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
            );
          })(),
        },
        {
          text: "Próxima semana",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  watch: {
    event(v) {
      this.event_ = v || {};
      this.eventDate = [
        new Date(v?.start_at),
        new Date(v?.expire_at ? v?.expire_at : v?.start_at),
      ];
    },
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar Evento" : "Cadastrar Evento";
    },
    DaysToNotificate() {
      const a = [];
      for (let p = 1; p <= 10; p++) {
        a.push({
          label: `${p} dias antes`,
          value: p,
        });
      }

      return a;
    },
    MonthsToRepeat() {
      return [
        {
          label: "Janeiro",
          value: 1,
        },
        {
          label: "Fevereiro",
          value: 2,
        },
        {
          label: "Março",
          value: 3,
        },
        {
          label: "Abril",
          value: 4,
        },
        {
          label: "Maio",
          value: 5,
        },
        {
          label: "Junho",
          value: 6,
        },
        {
          label: "Julho",
          value: 7,
        },
        {
          label: "Agosto",
          value: 8,
        },
        {
          label: "Setembro",
          value: 9,
        },
        {
          label: "Outubro",
          value: 10,
        },
        {
          label: "Novembro",
          value: 11,
        },
        {
          label: "Dezembro",
          value: 12,
        },
      ];
    },
    EventVisibility() {
      return [
        {
          value: "private",
          label: "Privado",
        },
        {
          value: "public",
          label: "Público",
        },
      ];
    },
    EventPriorities() {
      return [
        {
          value: "normal",
          label: "Normal",
        },
        {
          value: "urgent",
          label: "Urgente",
        },
        {
          value: "warning",
          label: "Aviso",
        },
      ];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    isNew() {
      return !("uid" in (this.event || {}));
    },
  },
  created() {
    //this.fetchSystemActions();
  },
  methods: {
    save() {
      this.isLoadingSave = true;

      return this.isNew ? this.createEvent() : this.updateEvent();
    },
    createEvent() {
      fetch(`${this.$store.state.apiUrl}events`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          ...this.event_,
          start_at: this.eventDate[0],
          expire_at: this.eventDate[1],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.$emit("should-update");
            ElNotification.success({
              title: `Evento cadastrado com sucesso!`,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao criar o evento",
              message: Array.isArray(e) ? e[0].message : e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    updateEvent() {
      fetch(`${this.$store.state.apiUrl}events/${this.event_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({
          ...this.event_,
          start_at: this.eventDate[0],
          expire_at: this.eventDate[1],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.$emit("should-update");
            ElNotification.success({
              title: `Evento alterado com sucesso!`,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao alterar o evento",
              message: Array.isArray(e) ? e[0].message : e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
  },
  name: "EventModal",
};
</script>
<style>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-1 {
  margin-top: 4px;
}
.stretch-width {
  width: 100% !important;
}
</style>